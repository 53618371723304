import { render, staticRenderFns } from "./CreateBulkQuestion.vue?vue&type=template&id=acf86f5a&scoped=true&"
import script from "./CreateBulkQuestion.vue?vue&type=script&lang=js&"
export * from "./CreateBulkQuestion.vue?vue&type=script&lang=js&"
import style0 from "./CreateBulkQuestion.vue?vue&type=style&index=0&id=acf86f5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf86f5a",
  null
  
)

export default component.exports